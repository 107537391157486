.city-map{
  position: relative;
  -webkit-box-pack: center;
  width: 100%;
  padding-top: 50px;
}

@media screen and (max-width: 640px) {
  .city-map{
    padding-top: 60px;
  }
}

.city-map__actions {
  z-index: 15;
  display: flex;
  justify-content: center;
}

.city-map__btn {
    font-family: "Play";
    text-align: center;
    font-weight: 700;
    position: relative;
    font-size: 40px;
    color: #ff5000;
    width: 55px;
    height: 55px;
    background: #ffe2d4;
    border-style: none;
    position: relative;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .city-map__btn {
      width: 40px;
      height: 40px;
      font-size: 32px;
    }
  }

.city-map__btn_in {
    margin-left: 11px;
}

.map__svg__part {
  opacity: 0;
}

.map {
  position: relative;
}

.map__svg {
  position: absolute;
}

.marker,
.marker-hover {
  position: absolute;
  width: 1.3%;
  z-index: 2;
  transition: opacity 0.2s ease;
}

.map__link:hover .marker {
  opacity: 0;
}

.map__link:hover .map__svg__part {
  transition: opacity 0.2s ease;
  opacity: 0.6;
}
