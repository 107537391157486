.desription {
    position: absolute;
    padding: 20px 30px;
    max-width: 587px;
    box-sizing: border-box;
    background: #fff;
    display: none;
    z-index: 3;
  }

  @media screen and (max-width: 1200px) {
    .desription {
      max-width: 50%;
      padding: 20px 10px;
    }
  }
  
  .desription__cross {
    background: url("../../assets/img/cross.jpg") no-repeat scroll right top
      rgba(0, 0, 0, 0);
    cursor: pointer;
    float: right;
    height: 16px;
    margin-top: 3px;
    padding-left: 15px;
    right: 10px;
    width: 16px;
  }
  
  .desription__content__building {
    background: #ebf3ff;
    height: 95px;
  }

  @media screen and (max-width: 900px) {
    .desription__content__building {
      display: none;
    }
  }
  
  .desription__content {
    display: flex;
  }
  
  .desription__clarity {
    padding-left: 15px;
  }
  
  .desription__clarity__heading {
    font-weight: 700;
    font-size: 13px;
    line-height: 110%;
  }
  
  .desription__clarity__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin-top: 10px;
  }

  .img {
    margin-top: 5px;
    width: 80px;
  }

  .desription__to__visit {
    display: block;
    color: #ff5000;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px 14px;
    background: #ffe2d5;
    margin-right: 10px;
    text-decoration: none;
    font-size: 12px;
  }

  @media screen and (max-width: 900px) {
    .desription__to__visit {
      margin-top: 10px;
      width: 52%;
    }
  }

  @media screen and (max-width: 480px) {
    .desription__to__visit {
      width: auto;
    }
  }

  .desription__button__tovisit {
    margin-top: 20px;
    display: flex;
  }

  @media screen and (max-width: 900px) {
    .desription__button__tovisit {
      flex-direction: column;
      margin-top: 10px;
    }
  }

  .desription__rhomb {
    margin-top: -40px;
    width: 0; 
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #FFFFFF;
    position: relative; 
    top: 20px;
    left: 50%
    }

    .desription__rhomb:after {
    content: "";
    width: 0; 
    height: 0;
    position: absolute; 
    top: 20px;
    left: -20px;
    border: 20px solid transparent;
    border-top-color: #FFFFFF;
    }
