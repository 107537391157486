.excursion {
    font-family: "Play";
    margin: 0 auto;
    width: 1200px;
    position: relative;
}

@media screen and (max-width: 1200px) {
    .excursion {
        width: 80%;
    }
}

.excursion__heading {
    font-weight: 700;
    font-size: 57px;
    line-height: 69px;
    text-transform: uppercase;
}

@media screen and (max-width: 645px) {
    .excursion__heading {
        font-size: 30px;
        line-height: 40px;
    }
}

.excursion__text {
    font-weight: 400;
    font-size: 23px;
    line-height: 150%;
    padding-top: 63px;
}

@media screen and (max-width: 645px) {
    .excursion__text {
        padding-top: 50px;
    }
}

.excursion__text__two {
    padding-top: 40px;
}

.map-wrapper {
    position: relative;
    overflow: hidden;
}

.excursion__fillings {
    display: flex;
    position: relative;
}

@media screen and (max-width: 980px) {
    .excursion__fillings {
        flex-direction: column;
    }
}

#excursion__fillings input {
    display: block;
    outline: none;
    border: none;
    padding: 17px;
    width: 387px;
    height: 56px;
    background: #eef1f2;
    margin-top: 20px;
    font-size: 20px;
}

.excursion__message {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
}

.excursion__error__message {
    color: red;
    display: inline-block;
}

.excursion__success__message {
    color: green;
    display: inline-block;
}

.excursion__captcha {
    padding-top: 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .excursion__captcha {
        padding-top: 15px;
    }
}

.excursion__fillings__purpose {
    display: block;
    outline: none;
    border: none;
    padding: 17px;
    height: 56px;
    background: #eef1f2;
    margin-top: 20px;
    width: 830px;
}

@media screen and (max-width: 980px) {
    #excursion__fillings input {
        width: 90%;
    }
}

@media screen and (max-width: 980px) {
    .excursion__fillings__purpose {
        width: 80%;
    }
}

.excursion__fillings__purpose::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.excursion__fillings input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.excursion__purpose__button {
    padding-bottom: 100px;
}

@media screen and (max-width: 980px) {
    .excursion__purpose__button {
        flex-direction: column;
    }
}

.excursion__fillings__right {
    margin-left: 20px;
}

@media screen and (max-width: 980px) {
    .excursion__fillings__right {
        margin-left: 0;
    }
}

.excursion__button[disabled][disabled] {
    background-color: #828282;
    cursor: not-allowed;
}

.excursion__button {
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    width: 241px;
    color: #ffffff;
    padding: 18px 30px;
    background: #ff5000;
    margin-top: 20px;
    cursor: pointer;
}

.excursion__button:hover {
    background-color: #ff8533;
}

.excursion__button[disabled] {
    background-color: #ff8533;
    cursor: default;
}

@media screen and (max-width: 980px) {
    .excursion__button {
        position: absolute;
    }
}

@media screen and (max-width: 340px) {
    .excursion__button {
        position: absolute;
        width: 80%;
    }
}

.error {
    color: red;
    position: absolute;
}
