.footer {
    background-color: #e1f0f9;
  }
  
  .footer__content {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1200px;
    padding: 40px 0px 30px 0px;
  }
  
  @media screen and (max-width: 1200px) {
    .footer__content {
      flex-direction: column;
      width: 80%;
    }
  }
  
  .footer__narrow {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #182d4c;
    text-align: center;
  }
  
  @media screen and (max-width: 1200px) {
    .footer__narrow {
      padding-bottom: 10px;
    }
  }
  
  .footer__wide {
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    color: #182d4c;
  }

  .footer__wide:hover {
    color: #ff5000;
  }
  
  @media screen and (max-width: 1200px) {
    .footer__wide {
      padding-bottom: 10px;
      text-align: center;
    }
  }
  
  .footer__logo {
    color: #182d4c;
    text-decoration: none;
  }

  .footer__logo:hover {
    color: #ff5000;
  }
  
  .footer__network {
    display: flex;
  }
  
  @media screen and (max-width: 1200px) {
    .footer__network {
      padding-bottom: 5px;
      margin-left: -27px;
      justify-content: center;
    }
  }

  .footer__namber {
    text-decoration: none;
  }

  .footer__up {
    content: "";
    width: 15px;
    display: block;
    height: 15px;
    border-top: 5px solid #ff5000 !important;
    border-right: 5px solid #ff5000!important;
    margin: 16px 0 0 10px;
    transform: rotate(-45deg);
    display: inline-block;
    transition: 1s;
    cursor: pointer;
  }

  .footer__up:hover {
    border-top: 5px solid #ff8533 !important;
    border-right: 5px solid #ff8533!important;
  }

  @media screen and (max-width: 1200px) {
    .footer__block__up {
      text-align: center;
    }
  }