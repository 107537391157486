  .header {
    background-color: #ffffff;
  }

  .logo{
    width: 183px;
    height: 35px;
  }

  .header__content {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
    justify-content: space-between;
    height: 90px;
  }
  
  @media screen and (max-width: 1200px) {
    .header__content {
      width: 85%;
    }
  }
  
  @media screen and (max-width: 450px) {
    .header__content {
      width: 95%;
    }
  }

  .header__poins_li {
    list-style: none;
  }

  @media screen and (max-width: 720px) {
    .logo {
      width: 140px;
      height: 30px;
    }
  }

  .header__link {
    font-family: "Play";
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-decoration: none;
    padding-left: 20px;
    color: #000000;
  }
  
  .header__link:hover {
    color: #ff5000;
  }
  
  .header__content__points {
    display: flex;
  }
  
  @media screen and (max-width: 1200px) {
    .header__content__points {
      display: none;
    }
  }
  
  .header__text {
    text-align: center;
    -webkit-box-pack: center;
    font-family: "Play";
    font-weight: 700;
    font-size: 63px;
    line-height: 110%;
    color: #002e6d;
    padding-top: 102px;
  }
  
  @media screen and (max-width: 500px) {
    .header__text {
      font-size: 40px;
    }
  }

  .header__social__network {
    display: flex;
  }
  
  @media screen and (max-width: 1200px) {
    .header__social__network {
      display: none;
    }
  }
  
  .header__social__network__burger__menu {
    display: flex;
    margin-left: -10px;
  }
  
  .menu__namber__burger {
    font-family: "Play";
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  
  .header__namber {
    font-family: "Play";
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-decoration: none;
    color: #000;
  }

  .header__namber:hover {
    color: #ff5000;
  }
  
  @media screen and (max-width: 1200px) {
    .header__namber {
      display: none;
    }
  }
  
  .header__button {
    display: block;
  }
  
  .header__button__text__to_visit {
    font-family: "Play";
    font-weight: 700;
    font-size: 16px;
    background: #ffe2d5;
    margin-right: 10px;
    padding: 14px 23px 14px;
    display: block;
    text-decoration: none;
    color: #ffffff;
    background: #ff5000;
    cursor: pointer; 
  }

  .header__button__text__to_visit:hover {
    background-color: #ff8533;
  }
  
  @media screen and (max-width: 450px) {
    .header__button__text__to_visit {
      padding: 1% 2%;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .header__button {
      display: flex;
    }
  }
  
  .header__burger__button {
    display: none;
    position: relative;
    width: 47px;
    height: 47px;
    background-color: #ff5000;
    border: none;
  }
  
  @media screen and (max-width: 1200px) {
    .header__burger__button {
      display: block;
    }
  }
  
  @media screen and (max-width: 450px) {
    .header__burger__button {
      width: 44px;
      height: 39px;
    }
  }
  
  .header__burger__line::before {
    position: absolute;
    left: 10px;
    top: 50%;
    width: 23px;
    height: 3px;
    content: "";
    background-color: #ffffff;
    transition: transform 0.6s ease-in-out;
  }
  
  .header__burger__line::after {
    position: absolute;
    left: 19px;
    top: 63%;
    width: 14px;
    height: 3px;
    content: "";
    background-color: #ffffff;
    transition: transform 0.6s ease-in-out;
  }
  
  .burger__line__active::before {
    left: 28%;
    top: 47%;
    transform: rotate(-45deg) translate(0px, -50%);
  }
  
  .burger__line__active::after {
    top: 45%;
    left: 10px;
    width: 23px;
    transform: rotate(45deg) translate(0px, -50%);
  }
  
  .header__burger__menu {
    position: absolute;
    right: 0;
    padding-left: 20px;
    top: 90px;
    z-index: 100;
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    transform: translateX(-100%);
    transition: transform 0.6s ease-in-out;
  }

  .header__burger__menu__active {
    transform: none;
  }

  .burger__menu__link {
    padding-top: 20px;
  }
  
  .burger__menu__namber {
    padding: 20px 0 20px 20px;
  }
  
  .header__page_lock {
    overflow: hidden;
  }
  
  .network__link {
    text-decoration: none;
    margin-left: 22px;
  }

  .network__logo:hover {
    filter: opacity(70%);
  }